// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

/* Dark theme styles */
body.dark-theme {
  background-color: #272B34;
  color: white;
}

nav.dark-theme {
  background-color: #272B34;
}

link-hover-color.dark-theme {
color: #136F63;
}

li.dark-theme {
color: white;
}
